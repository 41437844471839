<template>
    <div class="pages p20">
        <div class="title-btn-block">
            <div class="title-block">Qo'shimcha ishlar</div>
            <div>
                <button class="my-btn mr-2 el-button" @click="saveWorks" style="background:#00CC00; color:#fff; margin-right: 10px;" >
                    Сохранить
                </button>
                <router-link :to="{ name: 'additionalWork' }">
                    <div class="my-btn cr1 el-button">
                        <i class="el-icon-d-arrow-left"></i> Orqaga
                    </div>
                </router-link>
            </div>
        </div>

        <div class="content-block">
            <div class="datetime-end bg p20 mb20">
               <el-row :gutter="20">
                  <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
                    <div class="seloction mb20">
                      <el-select
                        v-model="form.performance_type"
                        placeholder="Ishlash turi"
                        class="w-100"
                        filterable
                        clearable
                        popper-class="my-data-khan"
                      >
                        <el-option label="To'lovli" value="1"></el-option>
                        <el-option label="To'lovsiz" value="0"></el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                    <div class="seloction mb20">
                      <el-select
                        v-model="form.additional_work_reason_id"
                        placeholder="Sabab"
                        class="w-100"
                        popper-class="my-data-khan"
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="(reason, index) in reasons"
                          :key="'guestreason-' + index"
                          :label="reason.name"
                          :value="reason.id"
                        ></el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                    <div class="seloction mb20 time_picer">
                     <!-- <el-time-picker
                     @change="setWorkTime"
                      v-model="form.work_time"
                       format="HH:mm:ss"
                      placeholder="Arbitrary time"
                    /> -->
                    <el-time-picker
                          v-model="form.work_time"
                          is-range
                          range-separator="To"
                          start-placeholder="Start time"
                          end-placeholder="End time"
                          value-format="HH:mm:ss"
                        />
                    </div>
                  </el-col>
               </el-row>
            </div>
            <div class="comment bg p20 mb20">
              <el-divider content-position="left"> {{ $t("message.comment") }} </el-divider>
              <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <el-input type="textarea" v-model="form.comment"></el-input>
                </el-col>
              </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import selectPerformanse from "@/components/filters/selects/select-performanse";

import {setItem, getItem } from "@/utils/storage";
import moment from 'moment';
import { mapGetters, mapActions } from "vuex";
export default {
    watch:{
      selected_perfor:{
        handler: function() {
            this.selected_perfor = getItem('performance_type')
        },
        immediate: true
      }
    },
    components:{
      selectPerformanse
    },
  data() {
    return {
      salarys:null,
      complaints:[],
      selected_perfor:null,
      work_time:getItem('work_time'),
      form: {
        performance_type:null,
        additional_work_reason_id:null,
        staff_id:getItem("userId"),
        comment:null,
        work_time:null
      },
      advance_salary:null,
    };
  },
  computed:{
    ...mapGetters({
      lists:"additionalWork/list",
      reasons:"additionalWork/reasons"
    })
  },
  mounted() {
    this.reasonsAction();
    this.updateList(getItem("userId"));
    
  },

  methods: {
    ...mapActions({
      updateList: "additionalWork/index",
      reasonsAction: "additionalWork/reasons",
      delete: "additionalWork/destroy",
      saveWorkAll:"additionalWork/saveAll"
    }),
    pushUrl(){
      this.form.staff_id = getItem("userId");
      if (this.form.performance_type && this.form.reason_id) {
        let route = this.$router.push({
              name: "scanner",
              params: {
                  id: this.form.staff_id,
                  performance_type: this.form.performance_type,
                  reason_id: this.form.reason_id,
                  
              },
            });
        window.open(route.href, "_self");
      } else {
        
      }
      
    },
    
    destroy(model) {
       this.$confirm(
              "Вы действительно хотите это сделать?",
              "Предупреждение", {
                confirmButtonText: "Да",
                cancelButtonText: "Отмен",
                type: "warning"
              }
            ).then(() => {
              this.delete(model.id)
                .then((res) => {
                  this.$alert(res);
                  this.updateList(getItem("userId"));
                })
                .catch((err) => {
                  this.$alert(err);
               });
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: "O'chirilish bekor qilindi"
              });
            });
      
    },

    saveWorks(){
         this.saveWorkAll(this.form)
          .then((res) => {
            this.$alert(res);
            this.updateList(getItem("userId"));
            let route = this.$router.push({
              name: "additionalWork",
              params: {
                  id: getItem("userId")
              },
            });
        window.open(route.href, "_self");
          })
          .catch((err) => {
            this.$alert(err);
          });
    },
    setPerformance(val){
      setItem('performance_type', val);
      this.selected_perfor = val
    },
     setWorkTime(val){
       setItem('work_time', 
                        [
                          moment(val[0]).format('YYYY-MM-DD HH:mm:ss'), 
                          moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
                        ]);
      this.work_time = [
                          moment(val[0]).format('YYYY-MM-DD HH:mm:ss'), 
                          moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
                        ];
    }
   
  },
};
</script>
<style lang="scss" >
  .remove-button{
    background-color: rgb(255, 0, 0);
    color: white;
    border: none;
    border-radius: 5px;
    width: 120px;
  }
  .time_picer{
    .el-range-input{
      color: #fff;
          background: transparent !important;
    }
    .el-range-separator{
      color: #fff;
    }
  }
</style>
